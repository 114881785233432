import React, { useState } from "react";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import CustomModal from "shared/components/modal/modal";
import Spinner from "shared/components/spinner/spinner";

interface UserPaymentInfoProps {
	paymentInfo: any;
	loading: boolean;
}

const UserPaymentInfo = (props: UserPaymentInfoProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [paymentData, setPaymentData] = useState<any>(null);
	const { paymentInfo, loading } = props;

	return (
		<>
			<div className="col-lg-12">
				<div className="ibox">
					<div className="ibox-content">
						<div className="">
							<table className="table table-bordered table-hover dataTables-example dataTable">
								<thead>
									<tr>
										<th className="text-capitalize cursor-pointer">
											Center
										</th>
										<th className="text-capitalize cursor-pointer">
											Payment Date
										</th>
										<th className="text-capitalize cursor-pointer">
											Payment Expiry Date
										</th>
										<th className="text-capitalize cursor-pointer">
											Action
										</th>
									</tr>
								</thead>
								<tbody className="stats-list">
									{loading && (
										<tr>
											<td colSpan={8}>
												<Spinner />
											</td>
										</tr>
									)}

									{!loading && paymentInfo?.length <= 0 && (
										<tr>
											<td colSpan={4}>
												<EmptyDataContainer />
											</td>
										</tr>
									)}
									{!loading &&
										paymentInfo?.length > 0 &&
										paymentInfo?.map(
											(item: any, index: number) => (
												<tr key={index}>
													<td>
														{item?.centerId?.name}
													</td>
													<td>
														{new Date(
															item.paymentDate
														).toLocaleDateString(
															"en-CA"
														)}
													</td>
													<td>
														{new Date(
															item.paymentExpiryDate
														).toLocaleDateString(
															"en-CA"
														)}
													</td>
													<td>
														<Button
															className="action-btn"
															btnType="primary"
															onClick={() => {
																setIsModalOpen(
																	true
																);
																setPaymentData(
																	item
																);
															}}
														>
															<i className="fa fa-eye" />
														</Button>
													</td>
												</tr>
											)
										)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{paymentData && (
				<CustomModal
					size="large"
					show={isModalOpen}
					handleClose={() => {
						setIsModalOpen(false);
					}}
				>
					<div>
						<h3>Payment Data</h3>
						<hr />
						<pre>{JSON.stringify(paymentData, null, 2)}</pre>
						{/* <div className="d-flex align-items-center  ">
							<h4 className=" ">Center :</h4>
							<h4 className=" ml-3 ">
								{paymentData?.centerId?.name}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment Amout :</h4>
							<h4 className=" ml-3 ">
								{paymentData?.paymentData?.amount}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment Created At :</h4>
							<h4 className=" ml-3 ">
								{
									paymentData?.paymentData?.createdAt
									// new Date(
									// 	paymentData?.paymentData?.createdAt
									// )
									// 	.toISOString()
									// 	.split("T")[0]
								}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment Expiry Date Pro :</h4>
							<h4 className=" ml-3 ">
								{
									// new Date(
									// 	paymentData?.paymentData?.paymentExpiryDatePro
									// )
									// 	.toISOString()
									// 	.split("T")[0]
									paymentData?.paymentData
										?.paymentExpiryDatePro
								}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment Date :</h4>
							<h4 className=" ml-3 ">
								{
									paymentData?.paymentDate
									// new Date(paymentData?.paymentDate)
									// 	.toISOString()
									// 	.split("T")[0]
								}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment Type :</h4>
							<h4 className=" ml-3 ">
								{paymentData?.paymentType}
							</h4>
						</div>
						<div className="d-flex align-items-center  ">
							<h4 className=" ">Payment amout :</h4>
							<h4 className=" ml-3 ">
								{paymentData?.paymentData?.amount}
							</h4>
						</div> */}
					</div>
				</CustomModal>
			)}
		</>
	);
};

export default UserPaymentInfo;
