import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { State, Action, ModalStatusProps, Pagination } from "shared/interface";
import Button from "shared/components/form/button";
import {
	modalStatusProps,
	API_CONFIGURATION,
	pageOptions,
} from "shared/constants/constants";
import { RouteComponentProps, withRouter } from "react-router";
import { UserCenterRoute } from "../actualTestUsers.interface";
import HttpService from "shared/services/http.service";
import { createAction, downloadGPXFile, formatDate } from "shared/util/utility";
import actionTypes from "store/action-types";
import { Link } from "react-router-dom";
import Spinner from "../../../shared/components/spinner/spinner";
import EmptyDataContainer from "../../../shared/components/emptyDataContainer/emptyDataContainer";
import PaginationComponent from "../../../shared/components/table/pagination";
import AndroidLogo from "../../../assets/images/android.png";
import AppleLogo from "../../../assets/images/apple.png";
import CustomModal from "../../../shared/components/modal/modal";
import MapTom from "shared/components/table/mapTom";
import Maps from "shared/components/table/map";
import { toastMessage } from "shared/components/notification/toastMessage";
import MapBox from "shared/components/table/mapbox";
import { UserTraversal } from "features/users/users.interface";

interface MapStateProps {
	open: boolean;
	action: string;
	routes: UserCenterRoute[];
}

interface DispatchProps extends ModalStatusProps {
	setMessage: (type: string, message: string) => void;
}

interface UIState {
	routes: UserCenterRoute[];
	loading: boolean;
	loadingAction: boolean;
	action: string;
	traversal: UserCenterRoute;
	pagination: Pagination;
}

const Centers = (
	props: MapStateProps &
		DispatchProps &
		RouteComponentProps<{ id: string; centerId: string }>
) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [routes, setRoutes] = useState<UserCenterRoute[]>([]);
	const [action, setAction] = useState("");
	const [traversal, setTraversal] = useState<any>([]);
	const [pagination, setPagination] = useState<Pagination>({
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	});

	const fetchCenterList = (parameters: { perPage: number; page: number }) => {
		setLoading(true);
		setPagination({
			...pagination,
			perPage: parameters.perPage,
			page: parameters.page,
		});
		HttpService.get(
			API_CONFIGURATION.path.actualTestUsers,
			`${props.match.params.id}/center/${props.match.params.centerId}`,
			parameters
		)
			.then((data) => {
				setLoading(false);
				setRoutes(data.routes);
				setPagination({
					...pagination,
					total: data.total,
					lastPage: Math.ceil(data.total / pagination.perPage),
				});
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};

	const onTraversalAction = async (
		actionData: string,
		traversalData: UserTraversal
	) => {
		if (traversal.id === traversalData.id && action === "view") {
			setAction("");
			setTraversal([]);
		}
		try {
			setLoadingAction(true);
			const res = await HttpService.get(
				API_CONFIGURATION.path.users,
				`traversals/gpx/${traversalData.id}`
			);

			if (res) {
				setLoadingAction(false);
				if (res.length > 0) {
					setTraversal(res);
					setAction(actionData);
				} else {
					props.setMessage("error", "Traversal Data not found!");
				}
			}
		} catch (e: any) {
			setLoadingAction(false);
			props.setMessage("error", e.message);
		}
		// setAction(actionData);
		// setTraversal(traversalData);
	};
	const getGpxFileOfTraversal = (traversalId: string) => {
		HttpService.get(
			API_CONFIGURATION.path.users,
			`traversals/gpx/${traversalId}`
		)
			.then((resp) => {
				const element = document.createElement("a");
				const file = new Blob([resp.gpxFile], {
					type: "application/gpx+xml",
				});
				element.href = URL.createObjectURL(file);
				element.download = "traversal.gpx";
				document.body.appendChild(element); // Required for this to work in FireFox
				element.click();
			})
			.catch((e) => {
				props.setMessage("error", e.message);
			});
	};
	const getQuery = (data: any): { perPage: number; page: number } => ({
		page: data.page === undefined ? pagination.page : data.page,
		perPage: data.perPage === undefined ? pagination.perPage : data.perPage,
	});
	const handlePageChange = (page: number) =>
		fetchCenterList(getQuery({ page: page }));
	const handlePageSelectionChange = (perPage: number) =>
		fetchCenterList(getQuery({ page: 1, perPage }));

	useEffect(() => {
		fetchCenterList(getQuery({}));

		return () => {};
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/actual-test-users">
							<p>Actual Test Users</p>
						</Link>
						<Link
							to={"/actual-test-users/" + props.match.params.id}
						>
							<p>/ Center List</p>
						</Link>
						<p>/ Routes </p>
					</div>
					<div className="btn-section">
						<Button
							onClick={props.history.goBack}
							className="mr-2 back-btn"
						>
							BACK
						</Button>
					</div>
				</div>

				<div className="row stats-wrapper">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							<div className="ibox-content">
								<PaginationComponent
									{...pagination}
									active={pagination.page}
									handlePageChange={handlePageChange}
									handlePageSelectionChange={
										handlePageSelectionChange
									}
								/>
								<div className="table-responsive">
									<table className="table table-bordered table-hover dataTables-example dataTable">
										<thead>
											<tr>
												<th className="text-capitalize cursor-pointer">
													#
												</th>
												<th className="text-capitalize cursor-pointer">
													Started At
												</th>
												<th className="text-capitalize cursor-pointer">
													Completed At
												</th>
												<th className="text-capitalize cursor-pointer">
													Duration (Minutes)
												</th>
												<th className="text-capitalize cursor-pointer">
													Center
												</th>
												<th
													className="text-capitalize cursor-pointer"
													style={{ width: "40px" }}
												>
													Platform
												</th>
												<th className="text-capitalize cursor-pointer">
													Status
												</th>
												<th className="text-capitalize cursor-pointer">
													Action
												</th>
											</tr>
										</thead>
										<tbody className="stats-list">
											{loading && (
												<tr>
													<td colSpan={8}>
														<Spinner />
													</td>
												</tr>
											)}
											{!loading && !routes.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
											{!loading &&
												routes.map((traversal, i) => (
													<tr key={i}>
														<td className="Cell">
															{i +
																1 +
																(pagination.page -
																	1) *
																	pagination.perPage}
														</td>
														<td className="Cell">
															{formatDate(
																traversal.startedAt
															)}
														</td>
														<td className="Cell">
															{formatDate(
																traversal.completedAt
															)}
														</td>
														<td className="Cell">
															{traversal.completedAt
																? Math.round(
																		(new Date(
																			traversal.completedAt
																		).getTime() -
																			new Date(
																				traversal.startedAt
																			).getTime()) /
																			(1000 *
																				60)
																  )
																: ""}
														</td>
														<td className="Cell">
															{!!traversal.centerId &&
																(traversal.traversalType ===
																"actual" ? (
																	traversal
																		.centerId
																		.name
																) : (
																	<Link
																		target="blank"
																		to={`/centers/${traversal.centerId._id}`}
																	>
																		{
																			traversal
																				.centerId
																				.name
																		}
																	</Link>
																))}
														</td>
														<td className="Cell">
															{traversal.platform &&
																traversal.platform
																	.toString()
																	.toLowerCase() ===
																	"android" && (
																	<img
																		alt="logo"
																		src={
																			AndroidLogo
																		}
																		className="logo"
																	/>
																)}
															{traversal.platform &&
																traversal.platform
																	.toString()
																	.toLowerCase() ===
																	"ios" && (
																	<img
																		alt="logo"
																		src={
																			AppleLogo
																		}
																		className="logo"
																	/>
																)}
														</td>
														<td className="Cell">
															{traversal.status}
														</td>
														<td className="Cell">
															<Button
																className="action-btn"
																btnType="primary"
																onClick={() => {
																	if (
																		traversal?.traversal
																	) {
																		onTraversalAction(
																			"view",
																			traversal
																		);
																	} else {
																		toastMessage(
																			"error",
																			"No traversal data found"
																		);
																	}
																}}
															>
																<i className="fa fa-eye" />
															</Button>
															{traversal.status ===
																"completed" && (
																<>
																	<Button
																		btnType="info"
																		onClick={() =>
																			downloadGPXFile(
																				traversal.traversal
																			)
																		}
																	>
																		GPX
																	</Button>
																</>
															)}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{action === "view" && (
					<CustomModal
						size="large"
						show={true}
						handleClose={() => {
							setAction(""), setTraversal({} as any);
						}}
					>
						<MapBox
							path={traversal.map((t: any) => ({
								lat: t.latitude,
								lng: t.longitude,
							}))}
							type="history"
						/>
					</CustomModal>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: State): MapStateProps =>
	({
		...state.common.modalStatus,
		// centers: state.actualTestUsers
	} as MapStateProps);

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Centers));
