import { formatDate } from "shared/util/utility";

const UserActiveCenterList = (props: any) => {
	console.log("UserActiveCenterList", props);

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						{/* <div className="table-responsive"> */}
						<table className="table table-bordered table-hover dataTables-example dataTable">
							<thead>
								<tr>
									<th className="text-capitalize cursor-pointer">
										#
									</th>
									<th className="text-capitalize cursor-pointer">
										Name
									</th>
									<th className="text-capitalize cursor-pointer">
										Payment Date
									</th>
									<th className="text-capitalize cursor-pointer">
										Expiry Date
									</th>
								</tr>
							</thead>
							<tbody className="stats-list">
								{props.cenetrList.map(
									(
										center: {
											centerId: string;
											name: string;
											paymentDate: number;
											expiryDate: number;
										},
										i: number
									) => (
										<tr key={i}>
											<td className="Cell">{i + 1}</td>
											<td className="Cell">
												{center.name}
											</td>
											<td className="Cell">
												{center.paymentDate
													? formatDate(
															center.paymentDate
													  )
													: ""}
											</td>
											<td className="Cell">
												{center.expiryDate
													? formatDate(
															center.expiryDate
													  )
													: ""}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};
export default UserActiveCenterList;
