import moment from "moment";
import { Action, PurchasePlan, State } from "../interface";
import { AppUser } from "../../features/users/users.interface";
import { toastMessage } from "shared/components/notification/toastMessage";

export const createAction = (ACTION: string, data: any = null): Action => {
	return {
		type: ACTION,
		payload: data,
	};
};

export const createLoadingSelector = (actions: string[]) => (state: State) => {
	// returns true only when all actions is not loading
	let loader = false;
	for (let i = 0; i < actions.length; i += 1) {
		if (state.loading.api[actions[i]]) {
			loader = true;
			break;
		}
	}
	return loader;
};

/**
 * function which returns formatted date
 * @param date
 */
export const formatDate = (date: any, format?: string) => {
	if (!date) {
		return "";
	}
	return moment(date)
		.local()
		.format(format || "YYYY-MM-DD HH:mm:ss");
};
/**
 * function which returns formatted date
 * @param user
 */
// export const ProUserPlan = (user: AppUser) => {
// 	return user.currentPlan ? `Subscription (${user?.activeCenter.length})` : 'Free'
// }

export const UserPlan = (
	user: AppUser,
	availablePurchasePlans: PurchasePlan[]
) => {
	if (user?.userType == "free") {
		return "Free";
	} else if (user?.numberOfActiveCenters > 1) {
		return `Subscription (${user?.numberOfActiveCenters})`;
	} else {
		const matchedPlan = availablePurchasePlans.find(
			(plan) => plan.id === user?.currentPlan
		);
		return matchedPlan ? matchedPlan.title : "Unknown Plan";
	}
};

export const UserPlanType = (user: AppUser) => {
	return user?.currentPlan?.split(".")[
		user?.currentPlan?.split(".")?.length - 1
	];
};

export const debounce = (func: any, wait = 500) => {
	let h: NodeJS.Timeout;
	return (...args: any) => {
		clearTimeout(h);
		h = setTimeout(() => func(...args), wait);
	};
};

export const downloadGPXFile = (url: string) => {
	if (url) {
		const element = document.createElement("a");
		element.href = url;
		element.download = "true";
		element.click();
	} else {
		toastMessage("error", "Traversal Data not found!");
	}
};
