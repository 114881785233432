import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import findIndex from "lodash/findIndex";

import { State, Action, ModalStatusProps } from "shared/interface";
import Button from "shared/components/form/button";
import {
	modalStatusProps,
	API_CONFIGURATION,
} from "shared/constants/constants";
import { RouteComponentProps, withRouter } from "react-router";
import {
	CenterRouteSchema,
	TestCenterParamSchema,
	CenterSchema,
} from "../centers.interface";
import CenterRouteList from "./routeList";
import HttpService from "shared/services/http.service";
import CenterRouteForm from "./centerRouteForm";
import Confirm from "shared/components/confirm/confirm";
import { confirmMessages } from "shared/constants/messages";
import { createAction } from "shared/util/utility";
import actionTypes from "store/action-types";
import { Link } from "react-router-dom";
import CustomModal from "shared/components/modal/modal";
import MapTom from "shared/components/table/mapTom";
import Spinner from "shared/components/spinner/spinner";
import { relativeTimeRounding } from "moment";
import Maps from "shared/components/table/map";
import CenterMapTom from "shared/components/table/centerMapTom";
import MapBox from "shared/components/table/mapbox";
import { toastMessage } from "shared/components/notification/toastMessage";

interface MapStateProps {
	open: boolean;
	action: string;
	centers: CenterSchema[];
}

interface DispatchProps extends ModalStatusProps {
	setMessage: (type: string, message: string) => void;
}

const Centers = (
	props: MapStateProps & DispatchProps & RouteComponentProps<{ id: string }>
) => {
	const [routes, setRoutes] = useState<CenterRouteSchema[]>([]);
	const [route, setRoute] = useState<CenterRouteSchema>({ name: "" } as any);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [action, setAction] = useState<string>("");
	const [traversal, setTraversal] = useState<any>({} as any);
	const [center, setCenter] = useState<CenterSchema>({} as any);

	const fetchCenterDetail = (centerId: string) => {
		HttpService.get(API_CONFIGURATION.path.centerRoutes, "", { centerId })
			.then((data) => {
				setLoading(false);
				setRoutes(data.centersRoutes);
				setCenter(data.center);
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};
	const fetchMapDetail = (routeId: string) => {
		HttpService.get(API_CONFIGURATION.path.centersRoute, `${routeId}`)
			.then((data) => {
				setLoadingAction(false);

				if (data.length > 0 && data[0].latitude && data[0].longitude) {
					setTraversal(data);
				} else {
					toastMessage("error", "No route data found");
					return;
				}
			})
			.catch((e) => {
				setLoadingAction(false);
				props.setMessage("error", e.message);
			});
	};
	const onRouteAction = (action: string, route: CenterRouteSchema) => {
		if (action === "view") {
			setLoadingAction(true);
			fetchMapDetail(route._id);
		}
		props.openModal(action);
		setRoute(route);
	};
	const onAddRoute = (route: TestCenterParamSchema, file: File) => {
		const formData = new FormData();
		formData.append("name", route.name);
		formData.append("centerId", props.match.params.id);
		formData.append("kmlfile", file);
		setLoadingAction(true);
		HttpService.post(API_CONFIGURATION.path.centerRoutes, "", formData, {
			contentType: "application/x-www-form-urlencoded",
		})
			.then((data) => {
				const routesData = [...routes];
				routesData.unshift(data.centersRoute);
				setRoutes(routesData);
				setLoadingAction(false);
				setRoute({ name: "" } as any);
				props.setMessage("success", "Center route has been added");
				props.closeModal();
			})
			.catch((e) => {
				setLoadingAction(false);
				props.setMessage("error", e.message);
			});
	};
	const onEditRoute = (routeData: TestCenterParamSchema, file: File) => {
		const formData = new FormData();
		formData.append("name", routeData.name);
		formData.append("centerId", props.match.params.id);
		if (file && file.name) {
			formData.append("kmlfile", file);
		}
		setLoadingAction(true);
		HttpService.put(
			API_CONFIGURATION.path.centerRoutes,
			route._id,
			formData,
			{ contentType: "application/x-www-form-urlencoded" }
		)
			.then((data) => {
				const routesData = [...routes].map((r) => {
					if (r._id === route._id) {
						r.name = routeData.name;
						if (data.filePath) {
							r.filePath = data.filePath;
						}
					}
					return r;
				});
				setRoutes(routesData);
				setLoadingAction(false);
				setRoute({ name: "" } as any);
				props.setMessage("success", "Center route has been updated");
				props.closeModal();
			})
			.catch((e) => {
				setLoadingAction(false);
				props.setMessage("error", e.message);
			});
	};
	const handleDelete = () => {
		setLoadingAction(true);
		HttpService.deleteRequest(
			API_CONFIGURATION.path.centerRoutes,
			route._id
		)
			.then((data) => {
				const routesData = [...routes];
				const index = findIndex(routesData, (r) => r._id === route._id);
				index !== -1 && routesData.splice(index, 1);
				setRoutes(routesData);
				setLoadingAction(false);
				setRoute({ name: "" } as any);
				props.setMessage("success", "Center route has been deleted");
				props.closeModal();
			})
			.catch((e) => {
				setLoadingAction(false);
				props.setMessage("error", e.message);
			});
	};

	useEffect(() => {
		fetchCenterDetail(props.match.params.id);
		if (props.centers && props.centers.length) {
			for (let i = 0; i < props.centers.length; i++) {
				if (props.centers[i]._id === props.match.params.id) {
					setCenter(props.centers[i]);
					break;
				}
			}
		}

		return () => {};
	}, []);

	console.log(traversal, "traversal");

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/centers">
							<p>All Centers / </p>
						</Link>
						<Link to={`/centers/${center._id}`}>
							<p>{center.name}</p>
						</Link>
					</div>
					<div className="btn-section">
						<Button
							onClick={props.history.goBack}
							className="mr-2 back-btn"
						>
							BACK
						</Button>
						<Button
							btnType="primary"
							onClick={() => props.openModal("add")}
						>
							ADD NEW ROUTE
						</Button>
					</div>
				</div>
				<CenterRouteList
					loading={loading}
					onRouteAction={onRouteAction}
					routes={routes}
				/>
				{props.open && (
					<>
						<CenterRouteForm
							show={
								props.action === "add" ||
								props.action === "edit"
							}
							loading={loadingAction}
							title={
								props.action === "add"
									? "Add New Route"
									: "Edit Route"
							}
							initialValues={{
								name: route.name,
								filePath: route.filePath,
							}}
							onCancel={props.closeModal}
							onSubmit={
								props.action === "add"
									? onAddRoute
									: onEditRoute
							}
						/>
						<Confirm
							loading={loadingAction}
							message={confirmMessages.confirmDelete(
								"Center Route"
							)}
							handleClose={props.closeModal}
							handleConfirm={handleDelete}
							show={props.open && props.action === "delete"}
						/>
						<CustomModal
							size="large"
							show={
								traversal.length > 0 &&
								traversal[0].latitude &&
								traversal[0].longitude &&
								props.open &&
								props.action === "view"
							}
							handleClose={() => {
								setAction("");
								setTraversal({});
								props.closeModal();
							}}
						>
							{loadingAction ? (
								<Spinner />
							) : traversal.length > 0 &&
							  traversal[0].latitude &&
							  traversal[0].longitude ? (
								<MapBox
									path={traversal?.map((t: any) => ({
										lat: t.latitude,
										lng: t.longitude,
									}))}
									type="center"
								/>
							) : (
								<div
									style={{
										height: "50vh",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										fontSize: "15px",
									}}
								>
									No traversal data found.
								</div>
							)}
						</CustomModal>
					</>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: State): MapStateProps => ({
	...state.common.modalStatus,
	centers: state.centers.centers,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Centers));
