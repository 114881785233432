import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { State, Action, ModalStatusProps, Pagination } from "shared/interface";
import Button from "shared/components/form/button";
import {
	modalStatusProps,
	API_CONFIGURATION,
	pageOptions,
} from "shared/constants/constants";
import { RouteComponentProps, withRouter } from "react-router";
import { UserTestCenter } from "../practice-user-report.interface";
import HttpService from "shared/services/http.service";
import { createAction } from "shared/util/utility";
import actionTypes from "store/action-types";
import { Link } from "react-router-dom";
import Spinner from "../../../shared/components/spinner/spinner";
import EmptyDataContainer from "../../../shared/components/emptyDataContainer/emptyDataContainer";
import PaginationComponent from "../../../shared/components/table/pagination";

interface MapStateProps {
	open: boolean;
	action: string;
	centers: UserTestCenter[];
}

interface DispatchProps extends ModalStatusProps {
	setMessage: (type: string, message: string) => void;
}

const PracticeUsersCenterReport = (
	props: MapStateProps & DispatchProps & RouteComponentProps<{ id: string }>
) => {
	const [centers, setCenters] = useState<UserTestCenter[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [pagination, setPagination] = useState<Pagination>({
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
	});

	const fetchCenterList = (parameters: { perPage: number; page: number }) => {
		setLoading(true);
		setPagination({
			...pagination,
			perPage: parameters.perPage,
			page: parameters.page,
		});
		HttpService.get(
			API_CONFIGURATION.path.reportPracticeUsers,
			props.match.params.id,
			parameters
		)
			.then((data) => {
				setLoading(false);
				setCenters(data.centers);
				setPagination({
					perPage: parameters.perPage,
					page: parameters.page,
					total: data.total,
					lastPage: Math.ceil(data.total / parameters.perPage),
				});
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};

	const handlePageChange = (page: number) =>
		fetchCenterList(getQuery({ page: page }));
	const handlePageSelectionChange = (perPage: number) =>
		fetchCenterList(getQuery({ page: 1, perPage }));
	const getQuery = (data: any): { perPage: number; page: number } => ({
		page: data.page === undefined ? pagination.page : data.page,
		perPage: data.perPage === undefined ? pagination.perPage : data.perPage,
	});

	useEffect(() => {
		fetchCenterList(getQuery({}));
	}, []);

	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/practice-users-report">
							<p>Practice Users</p>
						</Link>
						<p>/ Center List </p>
					</div>
					<div className="btn-section">
						<Button
							onClick={props.history.goBack}
							className="mr-2 back-btn"
						>
							BACK
						</Button>
					</div>
				</div>

				<div className="row stats-wrapper">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							<div className="ibox-content">
								<PaginationComponent
									{...pagination}
									active={pagination.page}
									handlePageChange={handlePageChange}
									handlePageSelectionChange={
										handlePageSelectionChange
									}
								/>
								<div className="table-responsive">
									<table className="table table-bordered table-hover dataTables-example dataTable">
										<thead>
											<tr>
												<th className="text-capitalize cursor-pointer">
													#
												</th>
												<th className="text-capitalize cursor-pointer">
													Name
												</th>
												<th className="text-capitalize cursor-pointer">
													Address
												</th>
												<th className="text-capitalize cursor-pointer">
													Total Practice
												</th>
												<th className="text-capitalize cursor-pointer">
													Action
												</th>
											</tr>
										</thead>
										<tbody className="stats-list">
											{loading && (
												<tr>
													<td colSpan={8}>
														<Spinner />
													</td>
												</tr>
											)}
											{!loading && !centers.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
											{!loading &&
												centers.map((center, i) => (
													<tr key={i}>
														<td className="Cell">
															{i + 1}
														</td>
														<td className="Cell">
															{center.name}
														</td>
														<td className="Cell">
															{center.address
																? center.address
																		.address
																: ""}
														</td>
														<td className="Cell">
															{center.total}
														</td>
														<td className="Cell">
															<Button
																className="action-btn"
																btnType="primary"
																link={`${props.match.params.id}/center/${center._id}`}
															>
																<i className="fa fa-eye" />
															</Button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: State): MapStateProps =>
	({
		...state.common.modalStatus,
	} as MapStateProps);

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PracticeUsersCenterReport));
