import queryString from "query-string";
import isEmpty from "lodash/isEmpty";
import { QueryParams, Action, ModalStatusProps } from "../interface";
import { ThunkDispatch } from "redux-thunk";
import { createAction } from "shared/util/utility";
import actionTypes from "store/action-types";

const API_CONFIGURATION = {
	baseUrl: process.env.REACT_APP_BASE_URL,
	s3Url: process.env.REACT_APP_S3_URL,
	TOM_TOM_KEY: process.env.REACT_APP_TOM_TOM_KEY,
	MAP_BOX_KEY: process.env.REACT_APP_MAP_BOX_KEY,
	path: {
		login: { api: "v1", url: "admin/login" },
		logout: { api: "v1", url: "admin/logout" },
		centers: { api: "v1", url: "center" },
		centersRoute: { api: "v1", url: "center/route" },
		dashboard: { api: "v1", url: "dashboard" },
		centerRoutes: { api: "v1", url: "center-route" },
		users: { api: "v1", url: "users" },
		actualTestUsers: { api: "v1", url: "report/actual/user" },
		reportPracticeUsers: { api: "v1", url: "report/test/user" },
		reportPracticeCenter: {
			api: "v1",
			url: "report/most-used-test/center",
		},
		adminConfig: { api: "v1", url: "admin/config" },
		sendNotification: { api: "v1", url: "admin/notification" },
		activeCenterList: { api: "v1", url: "user" },
		paymentHistory: { api: "v1", url: "user" },
	},
};

const buildApiUrl = (
	url: { api: string; url: string },
	pathParams: string = "",
	params: QueryParams = {}
): string => {
	let urlString = `${API_CONFIGURATION.baseUrl}/api/${url.api}/${url.url}/${pathParams}`;
	if (params && !isEmpty(params)) {
		urlString += `?${queryString.stringify(params)}`;
	}
	return urlString;
};

const buildFileUrl = (key: string) => {
	const url = `${API_CONFIGURATION.s3Url}/${key}`;
	return url;
};

const modalStatusProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): ModalStatusProps => ({
	openModal: (action) =>
		dispatch(createAction(actionTypes.OPEN_MODAL, action)),
	closeModal: () => dispatch(createAction(actionTypes.CLOSE_MODAL)),
});

const pageOptions = [10, 20, 50, 100];

const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export {
	pageOptions,
	API_CONFIGURATION,
	buildApiUrl,
	modalStatusProps,
	buildFileUrl,
	GOOGLE_MAP_API_KEY,
};
