import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import CustomModal from "shared/components/modal/modal";
import { Input } from "shared/components/form/input";
import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import { TestCenterParamSchema } from "../centers.interface";
import { toastMessage } from "shared/components/notification/toastMessage";

interface Props {
	show: boolean;
	loading: boolean;
	title: string;
	initialValues: any;
	onCancel: () => void;
	onSubmit: (values: TestCenterParamSchema, file: File) => void;
}

interface FileSchema {
	file: File | null;
	error: string;
}
// interface FileSchema {
//     file: File;
//     error: string;
// }

const CenterRouteForm = (props: Props) => {
	// const [file, changeFile] = React.useState({ file: {}, error: '' } as FileSchema);

	const [file, changeFile] = React.useState({
		file: null,
		error: "",
	} as FileSchema);

	const [fileError, setFileError] = useState<string | null>(null);

	const handleFileUpload = async (
		event: React.ChangeEvent<HTMLInputElement>
	): Promise<boolean> => {
		const file = event.target.files?.[0];
		if (!file) return false;

		if (!file.name.endsWith(".gpx")) {
			console.error("Error: File type must be .gpx");
			setFileError("Invalid file type. Please upload a .gpx file.");
			return false;
		}

		const reader = new FileReader();
		return new Promise((resolve) => {
			reader.onload = (e: ProgressEvent<FileReader>) => {
				if (e.target && typeof e.target.result === "string") {
					const isValid = validateGPX(e.target.result);
					resolve(isValid);
				} else {
					resolve(false);
				}
			};
			reader.readAsText(file);
		});
	};

	const validateGPX = (text: string): boolean => {
		setFileError(null);

		if (
			!text.includes(
				'<?xml version="1.0" encoding="UTF-8" standalone="no" ?>'
			)
		) {
			console.error("Error: Missing XML declaration");
			setFileError("Invalid GPX file: Missing XML declaration");
			return false;
		}

		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(text, "application/xml");

		const parserError = xmlDoc.getElementsByTagName("parsererror");
		if (parserError.length > 0) {
			const errorMessage =
				parserError[0].textContent || "Unknown XML parsing error";
			console.error(`Error: ${errorMessage}`);
			setFileError(`Invalid GPX file: ${errorMessage}`);
			return false;
		}

		const gpxTag = xmlDoc.getElementsByTagName("gpx");
		if (gpxTag.length === 0) {
			console.error("Error: Missing <gpx> root element");
			setFileError("Invalid GPX file: Missing <gpx> root element");
			return false;
		}

		const waypoints = xmlDoc.getElementsByTagName("wpt");
		if (waypoints.length === 0) {
			console.error("Error: No <wpt> elements found");
			setFileError("Invalid GPX file: No <wpt> elements found");
			return false;
		}

		for (let i = 0; i < waypoints.length; i++) {
			const wpt = waypoints[i];
			const lat = wpt.getAttribute("lat");
			const lon = wpt.getAttribute("lon");

			if (!lat || !lon) {
				console.error(
					`Error: Missing lat/lon attributes in <wpt> at line ${
						i + 1
					}`
				);
				setFileError(
					`Invalid GPX file: Missing lat/lon attributes in <wpt> at line ${
						i + 1
					}`
				);
				return false;
			}

			if (!isValidCoordinate(lat) || !isValidCoordinate(lon)) {
				console.error(
					`Error: Invalid lat/lon format at <wpt> line ${i + 1}`
				);
				setFileError(
					`Invalid GPX file: Invalid lat/lon format at <wpt> line ${
						i + 1
					}`
				);
				return false;
			}
		}
		return true;
	};

	const isValidCoordinate = (value: string): boolean => {
		return /^-?\d+(\.\d+)?$/.test(value.trim());
	};

	useEffect(() => {
		if (fileError) {
			toastMessage("error", `${fileError}`);
		}
	}, [fileError]);

	// const validateGPXFile = (file: File): Promise<boolean> => {
	// 	return new Promise((resolve, reject) => {
	// 		const reader = new FileReader();

	// 		reader.readAsText(file);

	// 		reader.onload = function (event: any) {
	// 			const gpxText = event.target.result;

	// 			try {
	// 				const parser = new DOMParser();
	// 				const xmlDoc = parser.parseFromString(
	// 					gpxText,
	// 					"application/xml"
	// 				);

	// 				const errorNode = xmlDoc.querySelector("parsererror");
	// 				console.log(errorNode, "errorNode");

	// 				if (errorNode) {
	// 					toastMessage("error", "Invalid XML format");
	// 					return resolve(false);
	// 				}

	// 				const gpxElement = xmlDoc.querySelector("gpx");
	// 				console.log(gpxElement, "gpxElement");

	// 				if (!gpxElement) {
	// 					toastMessage(
	// 						"error",
	// 						"Not a valid GPX file: missing <gpx> root element"
	// 					);
	// 					return resolve(false);
	// 				}

	// 				const trkElements = gpxElement.querySelectorAll("trk");
	// 				const wptElements = gpxElement.querySelectorAll("wpt");

	// 				console.log(trkElements, "trkElements");
	// 				console.log(wptElements, "wptElements");

	// 				if (trkElements.length === 0 && wptElements.length === 0) {
	// 					toastMessage(
	// 						"error",
	// 						"GPX file must contain at least one <trk> or <wpt> element"
	// 					);
	// 					return resolve(false);
	// 				}

	// 				return resolve(true);
	// 			} catch (error) {
	// 				console.error(error);
	// 				return reject(false);
	// 			}
	// 		};

	// 		reader.onerror = function () {
	// 			return reject(false);
	// 		};
	// 	});
	// };

	// const onFileInputChange = async (event: any): Promise<boolean> => {
	// 	const file = event.target.files[0];

	// 	if (!file) {
	// 		toastMessage("error", "No file selected");
	// 		return false;
	// 	}

	// 	const fileName = file.name.toLowerCase();
	// 	if (!fileName.endsWith(".gpx")) {
	// 		toastMessage("error", "Selected file is not a .gpx file");
	// 		(event.target as any).value = null;
	// 		return false;
	// 	}

	// 	try {
	// 		const isValid = await validateGPXFile(file);

	// 		console.log(isValid, "isValid");

	// 		if (!isValid) {
	// 			(event.target as any).value = null;
	// 			return false;
	// 		}

	// 		return true;
	// 	} catch (error) {
	// 		console.error(error);
	// 		(event.target as any).value = null;
	// 		return false;
	// 	}
	// };

	console.log(file.error, "file.error");
	return (
		<CustomModal show={props.show} handleClose={props.onCancel}>
			<div className="form">
				<h3 className="heading text-center">{props.title}</h3>
				<Formik
					initialValues={props.initialValues}
					validateOnBlur
					validateOnChange={false}
					// onSubmit={(data) => {
					// 	if (!file.file.name && !props.initialValues.filePath) {
					// 		changeFile({
					// 			file: file.file,
					// 			error: "please select file",
					// 		});
					// 	} else {
					// 		props.onSubmit(data, file.file);
					// 	}
					// }}
					onSubmit={(data) => {
						if (
							!file.file &&
							// !file.file.name &&
							!props.initialValues.filePath
						) {
							changeFile({
								file: file.file,
								error: "please select .gpx file",
							});
						} else {
							if (file.file) props.onSubmit(data, file.file);
						}
					}}
					validationSchema={schema.centerRouteFormValidationSchema}
				>
					{({ handleSubmit, setFieldValue }) => (
						<form onSubmit={handleSubmit}>
							<fieldset>
								<div className="form-group">
									<Input
										setFieldValue={setFieldValue}
										config={{
											type: "text",
											label: "Center Route Name",
											name: "name",
										}}
										placeholder="Center Route Name"
										type="text"
										name="name"
									/>
								</div>

								<div className="d-flex">
									<Button
										className="height-34px block m-b"
										type="button"
										onClick={() =>
											(
												document.getElementById(
													"fileinput-kmlfile"
												) as HTMLElement
											).click()
										}
										btnType="primary"
									>
										{props.initialValues.filePath
											? "OVERWRITE"
											: "SELECT"}{" "}
										GPX FILE
									</Button>

									{/* <input
                                        className='hidden'
                                        id='fileinput-kmlfile'
                                        type='file'
                                        onClick={(e) => {
                                            (e.target as any).value = null;
                                        }}
                                        onChange={(e) => {
                                            const file: File = (e.target.files as any)[0];
                                            file && changeFile({ file, error: '' });
                                            (e.target as any).value = null;
                                        }}
                                    />
                                    {!!file.file.name &&
                                        <div className='ml-2 kml-preview'>
                                            <i
                                                className='fa fa-minus'
                                                onClick={() => {
                                                    changeFile({ file: {}, error: '' } as FileSchema);
                                                }}
                                            />
                                            <i className='fa fa-file' />
                                        </div>
                                    } */}

									{/* <input
										className="hidden"
										id="fileinput-kmlfile"
										type="file"
										accept=".gpx"
										onClick={(e) => {
											(e.target as any).value = null;
										}}
										onChange={(e) => {
											onFileInputChange(e).then(
												(isValid: any) => {
													if (isValid) {
														const file: File = (
															e.target
																.files as any
														)[0];
														changeFile({
															file,
															error: "",
														});
														(
															e.target as any
														).value = null;
													} else {
														changeFile({
															file: null,
															error: "",
														} as FileSchema);
													}
												}
											);
										}}
									/>
									{file.file && !!file.file.name && (
										<div className="ml-2 kml-preview">
											<i
												className="fa fa-minus"
												onClick={() => {
													changeFile({
														file: null,
														error: "",
													} as FileSchema);
												}}
											/>
											<i className="fa fa-file" />
										</div>
									)} */}
									{/* </div>
								{file.error && (
									<p className="custom-error">{file.error}</p>
								)}*/}

									<div>
										<input
											className="hidden"
											type="file"
											id="fileinput-kmlfile"
											accept=".gpx"
											onChange={async (
												e: React.ChangeEvent<HTMLInputElement>
											) => {
												const result =
													await handleFileUpload(e);

												if (result) {
													const file: File = (
														e.target.files as any
													)[0];
													changeFile({
														file,
														error: "",
													});
													(e.target as any).value =
														null;
												} else {
													changeFile({
														file: null,
														error: "",
													} as FileSchema);
												}
											}}
										/>
										{file.file && !!file.file.name && (
											<div className="ml-2 kml-preview">
												<i
													className="fa fa-minus"
													onClick={() => {
														changeFile({
															file: null,
															error: "",
														} as FileSchema);
													}}
												/>
												<i className="fa fa-file" />
											</div>
										)}
										{file.error && (
											<p className="ml-2 custom-error">
												{file.error}
											</p>
										)}
										{/* {fileError && (
										<p style={{ color: "red" }}>
											{fileError}
										</p>
									)} */}
									</div>
								</div>

								<div className="mb-3 mt-3 d-flex align-items-center justify-content-center">
									<Button
										className="width-100px block m-b mr-1"
										loading={props.loading}
										onClick={props.onCancel}
										type="button"
										btnType="danger"
									>
										CANCEL
									</Button>
									<Button
										className="width-100px block m-b"
										loading={props.loading}
										type="submit"
										btnType="primary"
									>
										SUBMIT
									</Button>
								</div>
							</fieldset>
						</form>
					)}
				</Formik>
			</div>
		</CustomModal>
	);
};

export default CenterRouteForm;
