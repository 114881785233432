import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import actionTypes from "store/action-types";

import { createLoadingSelector, createAction } from "shared/util/utility";
import {
	State,
	Action,
	DashboardState,
	ModalStatusProps,
} from "shared/interface";
import { modalStatusProps } from "shared/constants/constants";
import Spinner from "shared/components/spinner/spinner";

import * as DashboardActions from "./dashboard.action";
import "./dashboard.scss";
import { Link } from "react-router-dom";

interface MapStateProps extends DashboardState {
	loading: boolean;
	proUserStatus: boolean;
}

interface DispatchProps extends ModalStatusProps {
	renderNav: () => void;
	fetchDashboardStats: () => void;
}

const Dashboard = (props: MapStateProps & DispatchProps) => {
	useEffect(() => {
		props.fetchDashboardStats();
	}, []);

	return (
		<>
			{props.loading && (
				<div className="mt-4">
					<Spinner />
				</div>
			)}
			{!props.loading && (
				// <div>
				// 	<p className="header-text mt-1">
				// 		Total Paid Users : {props.dashboardData.paidUsers}
				// 	</p>

				// 	<p className="header-text mt-4">
				// 		Paid Users By Plan & Platform
				// 	</p>
				// 	<Link to="/users?type=basic" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>{props.dashboardData.paidUsersBasic}</b>
				// 					<span className="mt-2">Basic Plan</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>
				// 	<Link to="/users?type=upgrade" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>
				// 						{props.dashboardData.paidUsersUpgrade}
				// 					</b>
				// 					<span className="mt-2">Upgraded Plan</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>

				// 	<Link to="/users?type=advanced" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>
				// 						{props.dashboardData.paidUsersAdvanced}
				// 					</b>
				// 					<span className="mt-2">Advanced Plan</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>{props.dashboardData.paidUsersAndroid}</b>
				// 				<span className="mt-2">Android</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-1">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>{props.dashboardData.paidUsersIos}</b>
				// 				<span className="mt-2">IOS</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-1">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>{props.dashboardData.paidUsersWeb}</b>
				// 				<span className="mt-2">Web</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>{props.dashboardData.complimentaryUsers}</b>
				// 				<span className="mt-2">Complimentary Plan</span>
				// 			</div>
				// 		</div>
				// 	</div>

				// 	<p className="header-text mt-4">Monthly subscription</p>
				// 	<Link to="/users?type=monthly" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-3 col-sm-4 col-xs-6 col-lg-3">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>
				// 						{
				// 							props.dashboardData
				// 								.totalMonthlySubscriptions
				// 						}
				// 					</b>
				// 					<span className="mt-2">
				// 						Total subscription
				// 					</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>
				// 	<div className=" col-md-3 col-sm-4 col-xs-6 col-lg-3">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.androidMonthlySubscriptions
				// 					}
				// 				</b>
				// 				<span className="mt-2">Android</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-3 col-sm-4 col-xs-6 col-lg-3">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.iosMonthlySubscriptions
				// 					}
				// 				</b>
				// 				<span className="mt-2">IOS</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-3 col-sm-4 col-xs-6 col-lg-3">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.webMonthlySubscriptions
				// 					}
				// 				</b>
				// 				<span className="mt-2">Web</span>
				// 			</div>
				// 		</div>
				// 	</div>

				// 	<p className="header-text mt-4">Centers & Routes</p>
				// 	<Link to="/centers?type=test" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-4 col-sm-4 col-xs-6 col-lg-4">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>{props.dashboardData.testCenters}</b>
				// 					<span className="mt-2">Test Centers</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>
				// 	<Link to="/centers?type=actual" onClick={props.renderNav}>
				// 		<div className="dashboard-pointer col-md-4 col-sm-4 col-xs-6 col-lg-4">
				// 			<div className="stati black left">
				// 				<div className="mt-2">
				// 					<b>{props.dashboardData.actualCenters}</b>
				// 					<span className="mt-2">Actual Centers</span>
				// 				</div>
				// 			</div>
				// 		</div>
				// 	</Link>
				// 	<div className=" col-md-4 col-sm-4 col-xs-6 col-lg-4">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>{props.dashboardData.totalPracticeRoutes}</b>
				// 				<span className="mt-2">
				// 					Total Practice Routes
				// 				</span>
				// 			</div>
				// 		</div>
				// 	</div>

				// 	<p className="header-text mt-4">
				// 		Test Traversals (Routes) Taken By Users
				// 	</p>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedTestTraversals
				// 					}
				// 				</b>
				// 				<span className="mt-2">Total Completed</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedTestTraversalsAndroid
				// 					}
				// 				</b>
				// 				<span className="mt-2">
				// 					Completed (Android)
				// 				</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedTestTraversalsIos
				// 					}
				// 				</b>
				// 				<span className="mt-2">Completed (IOS)</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledTestTraversals
				// 					}
				// 				</b>
				// 				<span className="mt-2">Total Cancelled</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledTestTraversalsAndroid
				// 					}
				// 				</b>
				// 				<span className="mt-2">
				// 					Cancelled (Android)
				// 				</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledTestTraversalsIos
				// 					}
				// 				</b>
				// 				<span className="mt-2">Cancelled (IOS)</span>
				// 			</div>
				// 		</div>
				// 	</div>

				// 	<p className="header-text mt-4">
				// 		Learning Traversals (Routes) Taken By Users
				// 	</p>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedActualTraversals
				// 					}
				// 				</b>
				// 				<span className="mt-2">Total Completed</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedActualTraversalsAndroid
				// 					}
				// 				</b>
				// 				<span className="mt-2">
				// 					Completed (Android)
				// 				</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.completedActualTraversalsIos
				// 					}
				// 				</b>
				// 				<span className="mt-2">Completed (IOS)</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledActualTraversals
				// 					}
				// 				</b>
				// 				<span className="mt-2">Total Cancelled</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledActualTraversalsAndroid
				// 					}
				// 				</b>
				// 				<span className="mt-2">
				// 					Cancelled (Android)
				// 				</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// 	<div className=" col-md-2 col-sm-2 col-xs-6 col-lg-2 mb-4 pb-4">
				// 		<div className="stati black left">
				// 			<div className="mt-2">
				// 				<b>
				// 					{
				// 						props.dashboardData
				// 							.cancelledActualTraversalsIos
				// 					}
				// 				</b>
				// 				<span className="mt-2">Cancelled (IOS)</span>
				// 			</div>
				// 		</div>
				// 	</div>
				// </div>
				<div>
					<p className="header-text mt-1">
						Total Paid Users : {props.dashboardData.paidUsers}
					</p>
					<p className="header-text mt-4">
						Paid Users By Plan & Platform
					</p>
					<div className="d-flex">
						<Link
							to="/users?type=basic"
							onClick={props.renderNav}
							className="block paid-platform-user-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>
											{props.dashboardData.paidUsersBasic}
										</b>
										<span className="mt-2">Basic Plan</span>
									</div>
								</div>
							</div>
						</Link>
						<Link
							to="/users?type=upgrade"
							onClick={props.renderNav}
							className="block paid-platform-user-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>
											{
												props.dashboardData
													.paidUsersUpgrade
											}
										</b>
										<span className="mt-2">
											Upgraded Plan
										</span>
									</div>
								</div>
							</div>
						</Link>

						<Link
							to="/users?type=advanced"
							onClick={props.renderNav}
							className="block paid-platform-user-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>
											{
												props.dashboardData
													.paidUsersAdvanced
											}
										</b>
										<span className="mt-2">
											Advanced Plan
										</span>
									</div>
								</div>
							</div>
						</Link>
						<div className="p-0-10 paid-platform-user-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{props.dashboardData.paidUsersAndroid}
									</b>
									<span className="mt-2">Android</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 paid-platform-user-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>{props.dashboardData.paidUsersIos}</b>
									<span className="mt-2">IOS</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 paid-platform-user-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>{props.dashboardData.paidUsersWeb}</b>
									<span className="mt-2">Web</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 paid-platform-user-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{props.dashboardData.complimentaryUsers}
									</b>
									<span className="mt-2">
										Complimentary Plan
									</span>
								</div>
							</div>
						</div>
					</div>

					<p className="header-text mt-4">Monthly subscription</p>
					<div className="d-flex">
						<Link
							to="/users?type=monthly"
							onClick={props.renderNav}
							className="block monthly-subscription-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>
											{
												props.dashboardData
													.totalMonthlySubscriptions
											}
										</b>
										<span className="mt-2">
											Total subscription
										</span>
									</div>
								</div>
							</div>
						</Link>
						<div className="monthly-subscription-card p-0-10">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.androidMonthlySubscriptions
										}
									</b>
									<span className="mt-2">Android</span>
								</div>
							</div>
						</div>
						<div className="monthly-subscription-card p-0-10">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.iosMonthlySubscriptions
										}
									</b>
									<span className="mt-2">IOS</span>
								</div>
							</div>
						</div>
						<div className="monthly-subscription-card p-0-10">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.webMonthlySubscriptions
										}
									</b>
									<span className="mt-2">Web</span>
								</div>
							</div>
						</div>
					</div>

					<p className="header-text mt-4">Centers & Routes</p>
					<div className="d-flex">
						<Link
							to="/centers?type=test"
							onClick={props.renderNav}
							className="block centers-routes-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>{props.dashboardData.testCenters}</b>
										<span className="mt-2">
											Test Centers
										</span>
									</div>
								</div>
							</div>
						</Link>
						<Link
							to="/centers?type=actual"
							onClick={props.renderNav}
							className="block centers-routes-card"
						>
							<div className="dashboard-pointer p-0-10">
								<div className="stati black left">
									<div className="mt-2">
										<b>
											{props.dashboardData.actualCenters}
										</b>
										<span className="mt-2">
											Actual Centers
										</span>
									</div>
								</div>
							</div>
						</Link>
						<div className=" p-0-10 centers-routes-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.totalPracticeRoutes
										}
									</b>
									<span className="mt-2">
										Total Practice Routes
									</span>
								</div>
							</div>
						</div>
					</div>

					<p className="header-text mt-4">
						Test Traversals (Routes) Taken By Users
					</p>
					<div className="d-flex">
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedTestTraversals
										}
									</b>
									<span className="mt-2">
										Total Completed
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedTestTraversalsAndroid
										}
									</b>
									<span className="mt-2">
										Completed (Android)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedTestTraversalsIos
										}
									</b>
									<span className="mt-2">
										Completed (IOS)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledTestTraversals
										}
									</b>
									<span className="mt-2">
										Total Cancelled
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledTestTraversalsAndroid
										}
									</b>
									<span className="mt-2">
										Cancelled (Android)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 test-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledTestTraversalsIos
										}
									</b>
									<span className="mt-2">
										Cancelled (IOS)
									</span>
								</div>
							</div>
						</div>
					</div>

					<p className="header-text mt-4">
						Learning Traversals (Routes) Taken By Users
					</p>
					<div className="d-flex mb-4">
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedActualTraversals
										}
									</b>
									<span className="mt-2">
										Total Completed
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedActualTraversalsAndroid
										}
									</b>
									<span className="mt-2">
										Completed (Android)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.completedActualTraversalsIos
										}
									</b>
									<span className="mt-2">
										Completed (IOS)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledActualTraversals
										}
									</b>
									<span className="mt-2">
										Total Cancelled
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledActualTraversalsAndroid
										}
									</b>
									<span className="mt-2">
										Cancelled (Android)
									</span>
								</div>
							</div>
						</div>
						<div className="p-0-10 learning-traversals-card">
							<div className="stati black left">
								<div className="mt-2">
									<b>
										{
											props.dashboardData
												.cancelledActualTraversalsIos
										}
									</b>
									<span className="mt-2">
										Cancelled (IOS)
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

const loadingSelector = createLoadingSelector([
	"FETCH_DASHBOARD_STATS",
	"FETCH_DASHBOARD_STATS_PRO",
]);

const mapStateToProps = (state: State): MapStateProps => ({
	...state.dashboard,
	proUserStatus: state.common.proUserStatus,
	loading: loadingSelector(state),
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	renderNav: () => dispatch(createAction(actionTypes.NO_ACTION)),
	fetchDashboardStats: () => dispatch(DashboardActions.fetchDashboardStats()),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(Dashboard);
