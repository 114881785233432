import React, { useEffect, useState } from "react";
import { GoLocation } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import { IoMdSearch } from "react-icons/io";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { State, Action, CentersState } from "../../interface";
import * as CenterActions from "../../../features/centers/centers.action";
import {
	CenterFetchParameters,
	CenterSchema,
} from "features/centers/centers.interface";
import "./locationDropdown.scss";
import { IconContext } from "react-icons";
import { AppUser } from "features/users/users.interface";

interface LocationDropdownProps {
	setCenter: (d: any) => void;
	user: AppUser;
	showCenters: boolean;
}
interface MapStateProps extends CentersState {
	loading: boolean;
	open: boolean;
	action: string;
	loadingAction: boolean;
}

interface DispatchProps {
	fetchTestCenters: (parameters: CenterFetchParameters) => void;
}

const LocationDropdown = (
	props: MapStateProps & DispatchProps & LocationDropdownProps
) => {
	const [dropdownPopupState, setDropdownPopupState] = useState(false);
	const [sortedlocationList, setSortedLocationList] = useState<
		CenterSchema[]
	>([]);
	const [selectState, setSelectState] = useState(false);
	const [selectedlocation, setSelectedLocation] = useState({} as any);

	const handleFilterCenter = (centers: CenterSchema[], user: AppUser) => {
		return centers.filter((center) => {
			return !user.centerNames.some((centerName: { _id: string }) => {
				return center._id === centerName._id;
			});
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSortedLocationList(
			handleFilterCenter(props.centers, props.user)?.filter(
				(location: any) =>
					location.name
						.toLowerCase()
						.includes(e.target.value.toLowerCase())
			)
		);
	};

	const handleLocationSelect = (location: any) => {
		setSelectedLocation(location);
		setSelectState(false);
		setDropdownPopupState(false);
		props.setCenter({
			id: location._id,
			name: location.name,
		});
	};

	useEffect(() => {
		setSortedLocationList(handleFilterCenter(props.centers, props.user));
	}, [props.centers, props.showCenters, props.user]);

	useEffect(() => {
		props.fetchTestCenters({ name: "", page: 1, perPage: 1000 });
	}, []);

	return (
		<div className="locationDropdown">
			<div
				className={
					selectState ? "blackBox disabledSelectMain" : "blackBox"
				}
				onClick={() => {
					setDropdownPopupState(true);
					setSelectState(!selectState);
				}}
			>
				<span>{selectedlocation.name || "Select Centre"}</span>
				<IconContext.Provider value={{ className: "goIcon" }}>
					<i>
						<GoLocation />
					</i>
				</IconContext.Provider>
			</div>
			<div
				className={`${
					dropdownPopupState
						? "dropdownPopup dropdownPopup__show"
						: "dropdownPopup"
				}`}
			>
				<div className="dropdownPopup__closeIcon">
					<IconContext.Provider value={{ className: "IoCloseIcon" }}>
						<i
							onClick={() => {
								setDropdownPopupState(false);
								setSelectState(false);
							}}
						>
							<IoClose />
						</i>
					</IconContext.Provider>
				</div>
				<div className="blackBox dropdownPopup__search">
					<input
						type="text"
						placeholder="Search here..."
						onChange={handleChange}
					/>
					<IconContext.Provider value={{ className: "IoSearchIcon" }}>
						<i>
							<IoMdSearch />
						</i>
					</IconContext.Provider>
				</div>

				<div className="dropdownPopup__locationList modernScrollBar">
					{sortedlocationList.length <= 0 && (
						<div className="dropdownPopup__locationList__empty">
							<p>No data found</p>
						</div>
					)}
					{sortedlocationList.length > 0 &&
						sortedlocationList.map((location) => (
							<div
								className="blackBox dropdownPopup__location"
								key={location._id}
								onClick={() => handleLocationSelect(location)}
							>
								<span>{location.name}</span>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: State): any => ({
	...state.centers,
});

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	fetchTestCenters: (parameters) =>
		dispatch(CenterActions.fetchTestCenters(parameters)),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(LocationDropdown);
