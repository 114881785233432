import React, { useState } from "react";

import { AppUser } from "./users.interface";
import Spinner from "shared/components/spinner/spinner";
import EmptyDataContainer from "shared/components/emptyDataContainer/emptyDataContainer";
import Button from "shared/components/form/button";
import {
	UserPlan,
	UserPlanType,
	createAction,
	formatDate,
} from "shared/util/utility";
import TableCell from "shared/components/table/tableCell";
import CustomModal from "shared/components/modal/modal";
import UserActiveCenterList from "./userActiveCenetrList";
import { API_CONFIGURATION } from "shared/constants/constants";
import HttpService from "shared/services/http.service";
import { toastMessage } from "shared/components/notification/toastMessage";
import { ThunkDispatch } from "redux-thunk";
import { Action, PurchasePlan } from "shared/interface";
import actionTypes from "store/action-types";

interface Props {
	users: AppUser[];
	loading: boolean;
	parameters: {
		name: string;
		email: string;
		userType: string;
		status: string;
		page: number;
		limit: number;
		orderBy: string;
		order: "asc" | "desc";
		platform: string;
	};
	availablePurchasePlans: PurchasePlan[];
	userTypeFilter: string;
	handleEmailChange: React.ChangeEventHandler<HTMLInputElement>;
	handleNameChange: React.ChangeEventHandler<HTMLInputElement>;
	handleUserTypeChange: React.ChangeEventHandler<HTMLSelectElement>;
	handlePlatformChange: React.ChangeEventHandler<HTMLSelectElement>;
	handleStatusChange: React.ChangeEventHandler<HTMLSelectElement>;
	onUserAction: (action: string, user: AppUser) => void;
	handleSortChange: (order: "asc" | "desc", orderBy: string) => void;
	setMessage: (type: string, message: string) => void;
}

const UsersList = (props: Props) => {
	const [action, setAction] = useState<string>("");
	const [cenetrList, setCenetrList] = useState([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [userName, setUserName] = useState<string>("");

	const getUserCenterList = async (id: string, name: string) => {
		try {
			setLoading(true);

			const res = await HttpService.get(
				API_CONFIGURATION.path.activeCenterList,
				`${id}/active-center`
			);

			if (res) {
				setLoading(false);
				if (res.centers.length > 0) {
					setCenetrList(res.centers);
					setAction("center");
					setUserName(name);
				} else {
					toastMessage("error", "Traversal Data not found!");
				}
			}
		} catch (e: any) {
			setLoading(false);
			props.setMessage("error", e.message);
		}
	};

	if (props.userTypeFilter === "drivingDateAdded") {
		return (
			<div className="row stats-wrapper">
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						<div className="ibox-content">
							<div className="table-responsive">
								<table className="table table-bordered table-hover dataTables-example dataTable">
									<thead>
										<tr>
											<TableCell
												header
												label="#"
												width="5%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "email",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Email"
												width="15%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "name",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Name"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "userType",
													handleSortChange:
														props.handleSortChange,
												}}
												label="User Type"
												width="20%"
												style={{ minWidth: "90px" }}
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "createdAt",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Created At"
												width="10%"
											/>
											<TableCell
												header
												label="Driving Date & Time"
												width="10%"
											/>
											<TableCell
												header
												label="Platform"
												width="5%"
											/>
											<TableCell
												header
												label="Action"
												width="15%"
												style={{ minWidth: "160px" }}
											/>
										</tr>
									</thead>
									<tbody className="stats-list">
										<tr>
											<TableCell />
											<TableCell
												searchParameters={{
													onChange:
														props.handleEmailChange,
													value: props.parameters
														.email,
													placeholder: "search email",
												}}
											/>
											<TableCell
												searchParameters={{
													onChange:
														props.handleNameChange,
													value: props.parameters
														.name,
													placeholder: "search name",
												}}
											/>
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Free",
															value: "free",
														},
														{
															label: "Basic",
															value: "basic",
														},
														{
															label: "Upgrade",
															value: "upgrade",
														},
														{
															label: "Advanced",
															value: "advanced",
														},
														{
															label: "Monthly",
															value: "monthly",
														},
														{
															label: "Complimentary",
															value: "complimentary",
														},
													],
													onChange:
														props.handleUserTypeChange,
													value: props.parameters
														.userType,
												}}
											/>
											<TableCell />
											<TableCell />
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Web",
															value: "web",
														},
														{
															label: "Ios",
															value: "ios",
														},
														{
															label: "Android",
															value: "Android",
														},
													],
													onChange:
														props.handlePlatformChange,
													value: props.parameters
														.platform,
												}}
											/>
											<TableCell />
										</tr>
										{props.loading && (
											<tr>
												<td colSpan={8}>
													<Spinner />
												</td>
											</tr>
										)}
										{!props.loading &&
											!props.users.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
										{!props.loading &&
											props.users.map((user, i) => (
												<tr key={i}>
													<td className="Cell">
														{i +
															1 +
															(props.parameters
																.page -
																1) *
																props.parameters
																	.limit}
													</td>
													<td className="Cell">
														{user.email}
													</td>
													<td className="Cell">
														{user.fullName}
													</td>
													<td className="Cell text-capitalize">
														{UserPlan(
															user,
															props.availablePurchasePlans
														)}
													</td>
													<td className="Cell">
														{formatDate(
															user.createdAt
														)}
													</td>
													<td className="Cell">
														{formatDate(
															user.drivingDate
																.date,
															"YYYY-MM-DD"
														)}{" "}
														{user.drivingDate.time}
													</td>
													<td className="Cell">
														{user.platform}
													</td>
													<td
														className="Cell"
														style={{
															textAlign: "left",
														}}
													>
														<Button
															className="mr-1"
															btnType="primary"
															onClick={() =>
																props.onUserAction(
																	"view",
																	user
																)
															}
														>
															<i className="fa fa-eye" />
														</Button>

														{UserPlanType(user) ===
															"monthly" && (
															<Button
																className="mr-1"
																btnType="primary"
																onClick={() =>
																	getUserCenterList(
																		user._id,
																		user.fullName
																	)
																}
															>
																active center
															</Button>
														)}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{action === "center" && (
					<CustomModal
						size="large"
						show={true}
						modalTitle={userName}
						handleClose={() => {
							setAction("");
						}}
					>
						<UserActiveCenterList
							cenetrList={cenetrList}
							loading={loading}
						/>
					</CustomModal>
				)}
			</div>
		);
	}

	if (props.userTypeFilter === "paidUsers") {
		return (
			<div className="row stats-wrapper">
				<div className="col-lg-12">
					<div className="ibox float-e-margins">
						<div className="ibox-content">
							<div className="table-responsive">
								<table className="table table-bordered table-hover dataTables-example dataTable">
									<thead>
										<tr>
											<TableCell
												header
												label="#"
												width="5%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "email",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Email"
												width="15%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "name",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Name"
												width="20%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "userType",
													handleSortChange:
														props.handleSortChange,
												}}
												label="User Type"
												width="20%"
												style={{ minWidth: "90px" }}
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "createdAt",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Created At"
												width="10%"
											/>
											<TableCell
												sortParameters={{
													order: props.parameters
														.order,
													orderBy:
														props.parameters
															.orderBy,
													name: "paymentDate",
													handleSortChange:
														props.handleSortChange,
												}}
												label="Purchase Date"
												width="10%"
											/>
											<TableCell
												header
												label="Platform"
												width="5%"
											/>
											<TableCell
												header
												label="Action"
												width="15%"
												style={{ minWidth: "160px" }}
											/>
										</tr>
									</thead>
									<tbody className="stats-list">
										<tr>
											<TableCell />
											<TableCell
												searchParameters={{
													onChange:
														props.handleEmailChange,
													value: props.parameters
														.email,
													placeholder: "search email",
												}}
											/>
											<TableCell
												searchParameters={{
													onChange:
														props.handleNameChange,
													value: props.parameters
														.name,
													placeholder: "search name",
												}}
											/>
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Basic",
															value: "basic",
														},
														{
															label: "Upgrade",
															value: "upgrade",
														},
														{
															label: "Advanced",
															value: "advanced",
														},
														{
															label: "Monthly",
															value: "monthly",
														},
														{
															label: "Complimentary",
															value: "complimentary",
														},
													],
													onChange:
														props.handleUserTypeChange,
													value: props.parameters
														.userType,
												}}
											/>
											<TableCell />
											<TableCell />
											<TableCell
												selectParameters={{
													options: [
														{
															label: "ALL",
															value: "",
														},
														{
															label: "Web",
															value: "web",
														},
														{
															label: "Ios",
															value: "ios",
														},
														{
															label: "Android",
															value: "Android",
														},
													],
													onChange:
														props.handlePlatformChange,
													value: props.parameters
														.platform,
												}}
											/>
											<TableCell />
										</tr>
										{props.loading && (
											<tr>
												<td colSpan={8}>
													<Spinner />
												</td>
											</tr>
										)}
										{!props.loading &&
											!props.users.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
										{!props.loading &&
											props.users.map((user, i) => (
												<tr key={i}>
													<td className="Cell">
														{i +
															1 +
															(props.parameters
																.page -
																1) *
																props.parameters
																	.limit}
													</td>
													<td className="Cell">
														{user.email}
													</td>
													<td className="Cell">
														{user.fullName}
													</td>
													<td className="Cell text-capitalize">
														{UserPlan(
															user,
															props.availablePurchasePlans
														)}
													</td>
													<td className="Cell">
														{formatDate(
															user.createdAt
														)}
													</td>
													<td className="Cell">
														{user.paymentDate
															? formatDate(
																	user.paymentDate
															  )
															: ""}
													</td>
													<td className="Cell">
														{user.platform}
													</td>
													<td
														className="Cell"
														style={{
															textAlign: "left",
														}}
													>
														<Button
															className="Action-btn mr-1"
															btnType="primary"
															onClick={() =>
																props.onUserAction(
																	"view",
																	user
																)
															}
														>
															<i className="fa fa-eye" />
														</Button>
														{UserPlanType(user) ===
															"monthly" && (
															<Button
																className="mr-1"
																btnType="primary"
																onClick={() =>
																	getUserCenterList(
																		user._id,
																		user.fullName
																	)
																}
															>
																active center
															</Button>
														)}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				{action === "center" && (
					<CustomModal
						size="large"
						show={true}
						modalTitle={userName}
						handleClose={() => {
							setAction("");
						}}
					>
						<UserActiveCenterList
							cenetrList={cenetrList}
							loading={loading}
						/>
					</CustomModal>
				)}
			</div>
		);
	}

	return (
		<div className="row stats-wrapper">
			<div className="col-lg-12">
				<div className="ibox float-e-margins">
					<div className="ibox-content">
						<div className="table-responsive">
							<table className="table table-bordered table-hover dataTables-example dataTable">
								<thead>
									<tr>
										<TableCell
											header
											label="#"
											width="5%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "email",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Email"
											width="15%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "fullName",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Name"
											width="10%"
										/>
										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "userType",
												handleSortChange:
													props.handleSortChange,
											}}
											label="User Type"
											width="10%"
											style={{ minWidth: "90px" }}
										/>

										<TableCell
											sortParameters={{
												order: props.parameters.order,
												orderBy:
													props.parameters.orderBy,
												name: "createdAt",
												handleSortChange:
													props.handleSortChange,
											}}
											label="Created At"
											width="10%"
										/>
										<TableCell
											header
											label="Purchase Date"
											width="10%"
										/>
										{/* <TableCell
											header
											label="Completed Test Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Completed Actual Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Cancelled Test Routes"
											width="5%"
										/>
										<TableCell
											header
											label="Cancelled Actual Routes"
											width="5%"
										/> */}
										<TableCell
											header
											label="Platform"
											width="5%"
										/>
										<TableCell
											header
											label="Action"
											width="15%"
											style={{ minWidth: "160px" }}
										/>
									</tr>
								</thead>
								<tbody className="stats-list">
									<tr>
										<TableCell />
										<TableCell
											searchParameters={{
												onChange:
													props.handleEmailChange,
												value: props.parameters.email,
												placeholder: "search email",
											}}
										/>
										<TableCell
											searchParameters={{
												onChange:
													props.handleNameChange,
												value: props.parameters.name,
												placeholder: "search name",
											}}
										/>
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Free",
														value: "free",
													},
													{
														label: "Basic",
														value: "basic",
													},
													{
														label: "Upgrade",
														value: "upgrade",
													},
													{
														label: "Advanced",
														value: "advanced",
													},
													// {
													// 	label: "Premium",
													// 	value: "premium",
													// },
													{
														label: "Monthly",
														value: "monthly",
													},
													{
														label: "Complimentary",
														value: "complimentary",
													},
												],
												onChange:
													props.handleUserTypeChange,
												value: props.parameters
													.userType,
											}}
										/>
										<TableCell />
										<TableCell />
										{/* <TableCell />
										<TableCell />
										<TableCell />
										<TableCell /> */}
										<TableCell
											selectParameters={{
												options: [
													{ label: "ALL", value: "" },
													{
														label: "Web",
														value: "web",
													},
													{
														label: "Ios",
														value: "ios",
													},
													{
														label: "Android",
														value: "Android",
													},
												],
												onChange:
													props.handlePlatformChange,
												value: props.parameters
													.platform,
											}}
										/>
										<TableCell />
									</tr>
									{props.loading && (
										<tr>
											<td colSpan={12}>
												<Spinner />
											</td>
										</tr>
									)}
									{!props.loading && !props.users.length && (
										<tr>
											<td colSpan={12}>
												<EmptyDataContainer />
											</td>
										</tr>
									)}
									{!props.loading &&
										props.users.map((user, i) => (
											<tr key={i}>
												<td className="Cell">
													{i +
														1 +
														(props.parameters.page -
															1) *
															props.parameters
																.limit}
												</td>
												<td className="Cell">
													{user.email}
												</td>
												<td className="Cell">
													{user.fullName}
												</td>
												<td className="Cell text-capitalize">
													{UserPlan(
														user,
														props.availablePurchasePlans
													)}
												</td>
												<td className="Cell">
													{formatDate(user.createdAt)}
												</td>
												<td className="Cell">
													{user.paymentDate
														? formatDate(
																user.paymentDate
														  )
														: ""}
												</td>
												{/* <td className="Cell">
													{
														user.completedTestTraversals
													}
												</td>
												<td className="Cell">
													{
														user.completedActualTraversals
													}
												</td>
												<td className="Cell">
													{
														user.cancelledTestTraversals
													}
												</td>
												<td className="Cell">
													{
														user.cancelledActualTraversals
													}
												</td> */}
												<td className="Cell">
													{user.platform}
												</td>
												{/* <td className='Cell'>{user.contactNumber || ''}</td> */}
												<td
													className="Cell"
													style={{
														textAlign: "left",
													}}
												>
													<Button
														className="mr-1"
														btnType="primary"
														onClick={() =>
															props.onUserAction(
																"view",
																user
															)
														}
													>
														<i className="fa fa-eye" />
													</Button>
													<Button
														className="Action-btn mr-1"
														btnType="default"
														onClick={() =>
															props.onUserAction(
																"edit",
																user
															)
														}
													>
														<i className="fa fa-pencil" />
													</Button>
													<Button
														className="Action-btn mr-1"
														btnType="danger"
														onClick={() =>
															props.onUserAction(
																"delete",
																user
															)
														}
													>
														<i className="fa fa-trash" />
													</Button>
													{UserPlanType(user) ===
														"monthly" && (
														<Button
															className="mr-1"
															btnType="primary"
															onClick={() =>
																getUserCenterList(
																	user._id,
																	user.fullName
																)
															}
														>
															active center
														</Button>
													)}
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			{action === "center" && (
				<CustomModal
					size="large"
					show={true}
					modalTitle={userName}
					handleClose={() => {
						setAction("");
					}}
				>
					<UserActiveCenterList
						cenetrList={cenetrList}
						loading={loading}
					/>
				</CustomModal>
			)}
		</div>
	);
};
export default UsersList;
