import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import CustomModal from "shared/components/modal/modal";
import { Input } from "shared/components/form/input";
import * as schema from "shared/constants/validation-schema";
import Button from "shared/components/form/button";
import FieldErrorMessage from "shared/components/form/error";
import { PurchasePlan } from "shared/interface";
import LocationDropdown from "shared/components/locationDropdown/locationDropdown";
import HttpService from "shared/services/http.service";
import { API_CONFIGURATION } from "shared/constants/constants";
import Spinner from "shared/components/spinner/spinner";

interface Props {
	show: boolean;
	loading: boolean;
	initialValues: any;
	userStatus: string;
	availablePurchasePlans: PurchasePlan[];
	onUserAction: (action: string) => void;
	onCancel: () => void;
	onSubmit: (values: any) => void;
	userId: string;
	setMessage: any;
	user: any;
	setCenter: (d: any) => void;
	setShowCenters: (d: boolean) => void;
	showCenters: boolean;
	center: any;
}

const userTypeValues = [
	{ key: "Paid", value: "paid" },
	{ key: "Free", value: "free" },
	{ key: "Complimentary", value: "complimentary" },
];

const userTypeValueKeys = userTypeValues.map((v) => v.value);

const EditUserForm = (props: Props) => {
	console.log(props, "props");

	const [paymentIdArr, setPaymentIdArr] = useState<string[]>([]);
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		HttpService.get(
			API_CONFIGURATION.path.paymentHistory,
			`${props.userId}/paymenthistory`
		)
			.then((data) => {
				setPaymentIdArr(data.map((d: any) => d._id));
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});

		return () => {
			props.setCenter({ id: "", name: "" });
			props.setShowCenters(false);
		};
	}, []);

	return (
		<CustomModal show={props.show} handleClose={props.onCancel}>
			<div className="form">
				<h3 className="heading text-center">Edit User Details</h3>
				{props.user.centerNames.length > 0 && (
					<div className="d-flex justify-content-between align-items-center">
						<div></div>
						<div className="d-flex align-items-center">
							<span className="">Show Free Centers</span>
							<div
								className="show-center-toggle-btn ml-2"
								onClick={() =>
									props.setShowCenters(!props.showCenters)
								}
							>
								{props.showCenters ? (
									<i className="fa fa-toggle-on" />
								) : (
									<i className="fa fa-toggle-off" />
								)}
							</div>
						</div>
					</div>
				)}

				{isLoading ? (
					<div className="pt-3">
						<Spinner />
					</div>
				) : (
					<Formik
						initialValues={{ ...props.initialValues, centerId: "" }}
						validateOnBlur
						validateOnChange={false}
						onSubmit={props.onSubmit}
						validationSchema={schema.userEditFormValidationSchema}
					>
						{({ handleSubmit, setFieldValue, values }) => (
							<form onSubmit={handleSubmit}>
								<fieldset>
									<div className="form-group">
										<label className="text-capitalize input-label-wrapper">
											Center ID
										</label>
										{(props.user.centerNames.length <= 0 ||
											props.showCenters) && (
											<LocationDropdown
												setCenter={props.setCenter}
												user={props.user}
												showCenters={props.showCenters}
											/>
										)}

										{props.user.centerNames.length > 0 &&
											!props.showCenters && (
												<select
													className="form-control"
													value={values.centerId}
													onChange={(e) => {
														const val: string =
															e.target.value;
														if (
															values.centerId ===
															""
														) {
															setFieldValue(
																"centerId",
																props.user
																	.centerNames[0]
																	._id
															);
														}
														setFieldValue(
															"centerId",
															val
														);
													}}
												>
													<option value="">
														Select Center
													</option>
													{props.user.centerNames.map(
														(center: any) => (
															<option
																value={
																	center._id
																}
																key={center._id}
															>
																{center.name}
															</option>
														)
													)}
												</select>
											)}
									</div>
									<div className="form-group">
										<Input
											setFieldValue={setFieldValue}
											config={{
												type: "text",
												label: "Name",
												name: "name",
											}}
											placeholder="Name"
											type="text"
											name="name"
										/>
									</div>

									<div className="form-group">
										<label className="text-capitalize input-label-wrapper">
											User Type
										</label>
										<select
											className="form-control"
											value={values.userType}
											onChange={(e) => {
												const val: string =
													e.target.value;
												if (
													userTypeValueKeys.includes(
														val
													)
												) {
													setFieldValue(
														"userType",
														val
													);
													if (
														val === "paid" &&
														values.currentPlan ===
															""
													) {
														setFieldValue(
															"currentPlan",
															props
																.availablePurchasePlans[0]
																.id
														);
													}
												}
											}}
										>
											{userTypeValues.map((val) => (
												<option {...val}>
													{val.key}
												</option>
											))}
										</select>
										<ErrorMessage
											name="userType"
											component={FieldErrorMessage}
										/>
									</div>

									{/* {values.userType === "paid" && (
										<div className="form-group">
											<label className="text-capitalize input-label-wrapper">
												User Type
											</label>
											<select
												className="form-control"
												value={values.currentPlan}
												onChange={(e) =>
													setFieldValue(
														"currentPlan",
														e.target.value
													)
												}
											>
												{props.availablePurchasePlans.map(
													(plan) => (
														<option
															value={plan.id}
															key={plan.id}
														>
															{plan.title}
														</option>
													)
												)}
											</select>
											<ErrorMessage
												name="currentPlan"
												component={FieldErrorMessage}
											/>
										</div>
									)} */}

									<div className="form-group">
										<Input
											setFieldValue={setFieldValue}
											config={{
												type: "password",
												label: "New Password",
												name: "password",
											}}
											placeholder="New Password"
											type="password"
											name="password"
										/>
									</div>

									<div className="mb-3 mt-3 d-flex align-items-center justify-content-center">
										<Button
											className="width-100px block m-b mr-1"
											loading={props.loading}
											onClick={props.onCancel}
											type="button"
											btnType="danger"
										>
											CANCEL
										</Button>
										<Button
											className="width-100px block m-b"
											loading={props.loading}
											type="submit"
											btnType="primary"
										>
											SUBMIT
										</Button>
									</div>
									<div className="mb-3 mt-3 d-flex align-items-center justify-content-center">
										{props.userStatus === "active" && (
											<Button
												className="block ml-1 m-b"
												btnType="warning"
												onClick={() =>
													props.onUserAction(
														"disable"
													)
												}
											>
												disable
											</Button>
										)}
										{props.userStatus === "suspended" && (
											<Button
												className="block ml-1 m-b"
												btnType="info"
												onClick={() =>
													props.onUserAction("enable")
												}
											>
												enable
											</Button>
										)}
									</div>
								</fieldset>
							</form>
						)}
					</Formik>
				)}
			</div>
		</CustomModal>
	);
};

export default EditUserForm;
