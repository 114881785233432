import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

const convertPathToNumbers = (path) => {
	return path
		.map(({ lat, lng }) => ({
			lat: parseFloat(lat),
			lng: parseFloat(lng),
		}))
		.filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng));
};

const chunkArray = (arr, maxSize) => {
	let result = [];
	for (let i = 0; i < arr.length; i += maxSize - 1) {
		result.push(arr.slice(i, i + maxSize));
	}
	return result;
};

const MapBox = ({ path, type }) => {
	const mapBoxKey = process.env.REACT_APP_MAP_BOX_KEY;
	const mapContainerRef = useRef(null);
	const mapRef = useRef(null);
	const [fullRoute, setFullRoute] = useState([]);

	mapboxgl.accessToken = mapBoxKey;
	const numericPath = convertPathToNumbers(path);
	const routeChunks = chunkArray(numericPath, 20);

	useEffect(() => {
		if (!numericPath.length || !mapContainerRef.current) return;

		if (!mapRef.current) {
			mapRef.current = new mapboxgl.Map({
				container: mapContainerRef.current,
				style: "mapbox://styles/mapbox/streets-v12",
				center: [numericPath[0].lng, numericPath[0].lat],
				zoom: 14,
				maxZoom: 18,
			});
		}

		const map = mapRef.current;

		map.on("load", async () => {
			if (type === "history") {
				routeChunks.forEach((chunk, index) => {
					const sourceId = `straight-route-${index}`;
					const layerId = `straight-route-${index}`;

					if (map.getSource(sourceId)) {
						map.getSource(sourceId).setData({
							type: "Feature",
							geometry: {
								type: "LineString",
								coordinates: chunk.map(({ lng, lat }) => [
									lng,
									lat,
								]),
							},
						});
					} else {
						map.addSource(sourceId, {
							type: "geojson",
							data: {
								type: "Feature",
								geometry: {
									type: "LineString",
									coordinates: chunk.map(({ lng, lat }) => [
										lng,
										lat,
									]),
								},
							},
						});

						map.addLayer({
							id: layerId,
							type: "line",
							source: sourceId,
							layout: {
								"line-join": "round",
								"line-cap": "round",
							},
							paint: {
								"line-color": "#FF0000",
								"line-width": 4,
							},
						});
					}
				});
			} else {
				const fetchRoutes = async () => {
					try {
						const promises = routeChunks.map((chunk) => {
							const coordinatesString = chunk
								.map(({ lng, lat }) => `${lng},${lat}`)
								.join(";");
							const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinatesString}?geometries=geojson&overview=full&access_token=${mapboxgl.accessToken}`;
							return fetch(url);
						});

						const responses = await Promise.all(promises);
						const data = await Promise.all(
							responses.map((res) => res.json())
						);

						const newRoutes = data
							.map(
								(item) =>
									item.routes[0]?.geometry?.coordinates || []
							)
							.flat();

						if (
							JSON.stringify(fullRoute) !==
							JSON.stringify(newRoutes)
						) {
							setFullRoute(newRoutes);
						}

						if (!map.getSource("route")) {
							map.addSource("route", {
								type: "geojson",
								data: {
									type: "Feature",
									geometry: {
										type: "LineString",
										coordinates: newRoutes,
									},
								},
							});

							map.addLayer({
								id: "route",
								type: "line",
								source: "route",
								layout: {
									"line-join": "round",
									"line-cap": "round",
								},
								paint: {
									"line-color": "#FF0000",
									"line-width": 4,
								},
							});
						} else {
							map.getSource("route").setData({
								type: "Feature",
								geometry: {
									type: "LineString",
									coordinates: newRoutes,
								},
							});
						}
					} catch (error) {
						console.error("Route error:", error);
					}
				};

				await fetchRoutes();
			}

			const bounds = new mapboxgl.LngLatBounds();
			numericPath.forEach(({ lng, lat }) => bounds.extend([lng, lat]));
			map.fitBounds(bounds, { padding: 50 });

			if (type === "history" && numericPath.length > 1) {
				new mapboxgl.Marker({ color: "green", scale: 1 })
					.setLngLat([numericPath[0].lng, numericPath[0].lat])
					.addTo(map);

				new mapboxgl.Marker({ color: "red", scale: 1 })
					.setLngLat([
						numericPath[numericPath.length - 1].lng,
						numericPath[numericPath.length - 1].lat,
					])
					.addTo(map);
			} else {
				numericPath.forEach((coord, index) => {
					new mapboxgl.Marker({
						color:
							index === 0
								? "green"
								: index === numericPath.length - 1
								? "red"
								: "black",
						scale: 1,
					})
						.setLngLat([coord.lng, coord.lat])
						.addTo(map);
				});
			}
		});
	}, [path, type]);

	return (
		<div
			ref={mapContainerRef}
			style={{ height: "calc(100vh - 150px)", width: "100%" }}
		/>
	);
};

export default MapBox;
