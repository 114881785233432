import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import { State, Action, ModalStatusProps } from "shared/interface";
import Button from "shared/components/form/button";
import {
	modalStatusProps,
	API_CONFIGURATION,
	pageOptions,
} from "shared/constants/constants";
import { RouteComponentProps, withRouter } from "react-router";
import { UserCenterRoute } from "../practice-user-report.interface";
import HttpService from "shared/services/http.service";
import { createAction, downloadGPXFile, formatDate } from "shared/util/utility";
import actionTypes from "store/action-types";
import { Link } from "react-router-dom";
import Spinner from "../../../shared/components/spinner/spinner";
import EmptyDataContainer from "../../../shared/components/emptyDataContainer/emptyDataContainer";
import PaginationComponent from "../../../shared/components/table/pagination";
import AndroidLogo from "../../../assets/images/android.png";
import AppleLogo from "../../../assets/images/apple.png";
import CustomModal from "../../../shared/components/modal/modal";
import Maps from "../../../shared/components/table/map";
import MapTom from "shared/components/table/mapTom";
import { toastMessage } from "shared/components/notification/toastMessage";
import MapBox from "shared/components/table/mapbox";
import { UserTraversal } from "features/users/users.interface";

interface MapStateProps {
	open: boolean;
	action: string;
	routes: UserCenterRoute[];
}

interface DispatchProps extends ModalStatusProps {
	setMessage: (type: string, message: string) => void;
}

interface Pagination {
	lastPage: number;
	page: number;
	total: number;
	perPage: number;
	order: string;
	orderBy: string;
}

const PracticeUsersCenterRouteReport = (
	props: MapStateProps &
		DispatchProps &
		RouteComponentProps<{ id: string; centerId: string }>
) => {
	const [routes, setRoutes] = useState<UserCenterRoute[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingAction, setLoadingAction] = useState<boolean>(false);
	const [action, setAction] = useState<string>("");
	const [traversal, setTraversal] = useState<any>([]);
	const [parameters, setParameters] = useState<any>({
		orderBy: "",
		order: "desc",
	});
	const [pagination, setPagination] = useState<Pagination>({
		lastPage: 1,
		page: 1,
		perPage: pageOptions[0],
		total: 1,
		order: "",
		orderBy: "desc",
	});

	const fetchCenterList = (parameters: {
		perPage: number;
		page: number;
		order: string;
		orderBy: string;
	}) => {
		setLoading(true);
		setPagination({
			...pagination,
			order: parameters.order,
			orderBy: parameters.orderBy,
			perPage: parameters.perPage,
			page: parameters.page,
		});

		HttpService.get(
			API_CONFIGURATION.path.reportPracticeUsers,
			`${props.match.params.id}/center/${props.match.params.centerId}`,
			parameters
		)
			.then((data) => {
				setLoading(false);
				setRoutes(data.routes);
				setPagination({
					order: parameters.order,
					orderBy: parameters.orderBy,
					perPage: parameters.perPage,
					page: parameters.page,
					total: data.total,
					lastPage: Math.ceil(data.total / parameters.perPage),
				});
			})
			.catch((e) => {
				setLoading(false);
				props.setMessage("error", e.message);
			});
	};
	const onTraversalAction = async (
		actionData: string,
		traversalData: UserTraversal
	) => {
		if (traversal.id === traversalData.id && action === "view") {
			setAction("");
			setTraversal([]);
		}
		try {
			setLoadingAction(true);
			const res = await HttpService.get(
				API_CONFIGURATION.path.users,
				`traversals/gpx/${traversalData.id}`
			);

			if (res) {
				setLoadingAction(false);
				if (res.length > 0) {
					setTraversal(res);
					setAction(actionData);
				} else {
					props.setMessage("error", "Traversal Data not found!");
				}
			}
		} catch (e: any) {
			setLoadingAction(false);
			props.setMessage("error", e.message);
		}
		// setAction(actionData);
		// setTraversal(traversalData);
	};

	const handlePageChange = (page: number) =>
		fetchCenterList(getQuery({ page: page }));
	const handlePageSelectionChange = (perPage: number) =>
		fetchCenterList(getQuery({ page: 1, perPage }));
	const handleSortChange = (order: "asc" | "desc", orderBy: string) =>
		fetchCenterList(
			getQuery({
				order,
				orderBy,
			})
		);
	const getQuery = (
		data: any
	): { perPage: number; page: number; order: string; orderBy: string } => ({
		order: data.order === undefined ? parameters.order : data.order,
		orderBy: data.orderBy === undefined ? parameters.orderBy : data.orderBy,
		page: data.page === undefined ? pagination.page : data.page,
		perPage: data.perPage === undefined ? pagination.perPage : data.perPage,
	});

	useEffect(() => {
		fetchCenterList(getQuery({}));
	}, []);
	return (
		<>
			<div>
				<div className="justify-content-between d-flex page-heading">
					<div className="heading">
						<Link to="/practice-users-report">
							<p>Practice Users</p>
						</Link>
						<Link
							to={
								"/practice-users-report/" +
								props.match.params.id
							}
						>
							<p>/ Center List</p>
						</Link>
						<p>/ Routes </p>
						<p>/ Center List </p>
					</div>
					<div className="btn-section">
						<Button
							onClick={props.history.goBack}
							className="mr-2 back-btn"
						>
							BACK
						</Button>
					</div>
				</div>

				<div className="row stats-wrapper">
					<div className="col-lg-12">
						<div className="ibox float-e-margins">
							<div className="ibox-content">
								<PaginationComponent
									{...pagination}
									active={pagination.page}
									handlePageChange={handlePageChange}
									handlePageSelectionChange={
										handlePageSelectionChange
									}
								/>
								<div className="table-responsive">
									<table className="table table-bordered table-hover dataTables-example dataTable">
										<thead>
											<tr>
												<th className="text-capitalize cursor-pointer">
													#
												</th>
												<th className="text-capitalize cursor-pointer">
													Started At
												</th>
												<th className="text-capitalize cursor-pointer">
													Completed At
												</th>
												<th className="text-capitalize cursor-pointer">
													Duration (Minutes)
												</th>
												<th className="text-capitalize cursor-pointer">
													Center
												</th>
												<th className="text-capitalize cursor-pointer">
													Route
												</th>
												<th
													className="text-capitalize cursor-pointer"
													style={{ width: "40px" }}
												>
													Platform
												</th>
												<th className="text-capitalize cursor-pointer">
													Status
												</th>
												<th className="text-capitalize cursor-pointer">
													Action
												</th>
											</tr>
										</thead>
										<tbody className="stats-list">
											{loading && (
												<tr>
													<td colSpan={8}>
														<Spinner />
													</td>
												</tr>
											)}
											{!loading && !routes.length && (
												<tr>
													<td colSpan={8}>
														<EmptyDataContainer />
													</td>
												</tr>
											)}
											{!loading &&
												routes.map((traversal, i) => (
													<tr key={i}>
														<td className="Cell">
															{i +
																1 +
																(pagination.page -
																	1) *
																	pagination.perPage}
														</td>
														<td className="Cell">
															{formatDate(
																traversal.startedAt
															)}
														</td>
														<td className="Cell">
															{formatDate(
																traversal.completedAt
															)}
														</td>
														<td className="Cell">
															{traversal.completedAt
																? Math.round(
																		(new Date(
																			traversal.completedAt
																		).getTime() -
																			new Date(
																				traversal.startedAt
																			).getTime()) /
																			(1000 *
																				60)
																  )
																: ""}
														</td>
														<td className="Cell">
															{!!traversal.centerId &&
																(traversal.traversalType ===
																"actual" ? (
																	traversal
																		.centerId
																		.name
																) : (
																	<Link
																		target="blank"
																		to={`/centers/${traversal.centerId._id}`}
																	>
																		{
																			traversal
																				.centerId
																				.name
																		}
																	</Link>
																))}
														</td>
														<td className="Cell">
															{traversal.centerRouteId
																? traversal
																		.centerRouteId
																		.name
																: ""}
														</td>
														<td className="Cell">
															{traversal.platform &&
																traversal.platform
																	.toString()
																	.toLowerCase() ===
																	"android" && (
																	<img
																		alt="logo"
																		src={
																			AndroidLogo
																		}
																		className="logo"
																	/>
																)}
															{traversal.platform &&
																traversal.platform
																	.toString()
																	.toLowerCase() ===
																	"ios" && (
																	<img
																		alt="logo"
																		src={
																			AppleLogo
																		}
																		className="logo"
																	/>
																)}
														</td>
														<td className="Cell">
															{traversal.status}
														</td>
														<td className="Cell">
															<Button
																className="action-btn"
																btnType="primary"
																onClick={() => {
																	if (
																		traversal?.traversal
																	) {
																		onTraversalAction(
																			"view",
																			traversal
																		);
																	} else {
																		toastMessage(
																			"error",
																			"No traversal data found"
																		);
																	}
																}}
															>
																<i className="fa fa-eye" />
															</Button>
															{traversal.status ===
																"completed" && (
																<>
																	<Button
																		btnType="info"
																		onClick={() =>
																			downloadGPXFile(
																				traversal.traversal
																			)
																		}
																	>
																		GPX
																	</Button>
																</>
															)}
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
				{action === "view" && (
					<CustomModal
						size="large"
						show={true}
						handleClose={() => {
							setAction(""), setTraversal({} as any);
						}}
					>
						<MapBox
							path={traversal.map((t: any) => ({
								lat: t.latitude,
								lng: t.longitude,
							}))}
							type="history"
						/>
					</CustomModal>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state: State): MapStateProps =>
	({
		...state.common.modalStatus,
	} as MapStateProps);

const mapDispatchToProps = (
	dispatch: ThunkDispatch<{}, {}, Action>
): DispatchProps => ({
	...modalStatusProps(dispatch),
	setMessage: (type, message) =>
		dispatch(createAction(actionTypes.SET_MESSAGE, { type, message })),
});

export default connect<MapStateProps, DispatchProps, {}, State>(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PracticeUsersCenterRouteReport));
